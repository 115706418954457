/*--------------------------------------------------------------
# Skills
--------------------------------------------------------------*/
.skills .progress {
    height: 60px;
    display: block;
    background: none;
    border-radius: 0;
}

.skills .progress .skill {
    padding: 10px 0;
    margin: 0;
    text-transform: uppercase;
    display: block;
    font-weight: 600;
    font-family: "Poppins", sans-serif;
    color: #fff;
}

.skills .progress .skill .val {
    float: right;
    font-style: normal;
}

.skills .progress-bar-wrap {
    background: rgba(255, 255, 255, 0.2);
}

.skills .progress-bar {
    width: 1px;
    height: 10px;
    transition: .9s;
    background-color: #18d26e;
}

.skillbar-percent{
 font-size:20px !important;
    color:rgb(34 20 20);
}