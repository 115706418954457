.label-color{
    color: black!important;
    font-weight: bold;
}


.img-mythumbnail {
    padding: .25rem;
    background-color: #fff;
    border: 1px solid #dee2e6;
    border-radius: .25rem;
    max-width: 100%;
}