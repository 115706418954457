/*--------------------------------------------------------------
# Counts
--------------------------------------------------------------*/
.counts {
    padding: 70px 0 60px;
}

.counts .count-box {
    padding: 30px 30px 25px 30px;
    width: 100%;
    position: relative;
    text-align: center;
    background: rgba(255, 255, 255, 0.08);
    margin: 20px;
}

.counts .count-box i {
    position: absolute;
    top: -25px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 24px;
    background: rgba(255, 255, 255, 0.1);
    padding: 12px;
    color: #18d26e;
    border-radius: 50px;
    line-height: 0;
}

.counts .count-box span {
    font-size: 36px;
    display: block;
    font-weight: 600;
    color: #fff;
}

.counts .count-box p {
    padding: 0;
    margin: 0;
    font-family: "Raleway", sans-serif;
    font-size: 14px;
}