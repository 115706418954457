.each-slide > div {
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: 100% 100%;

    width: 100%;
    height: 600px;
}

.each-slide span {
    padding: 20px;
    font-size: 20px;
    background: #efefef;
    text-align: center;
}