/*!*--------------------------------------------------------------*/
/*# Testimonials*/
/*--------------------------------------------------------------*!*/
/*.testimonials .testimonial-item {*/
/*    box-sizing: content-box;*/
/*    min-height: 320px;*/
/*}*/

/*.testimonials .testimonial-item .testimonial-img {*/
/*    width: 90px;*/
/*    border-radius: 50%;*/
/*    margin: -40px 0 0 40px;*/
/*    position: relative;*/
/*    z-index: 2;*/
/*    border: 6px solid rgba(255, 255, 255, 0.12);*/
/*}*/

/*.testimonials .testimonial-item h3 {*/
/*    font-size: 18px;*/
/*    font-weight: bold;*/
/*    margin: 10px 0 5px 45px;*/
/*    color: #fff;*/
/*}*/

/*.testimonials .testimonial-item h4 {*/
/*    font-size: 14px;*/
/*    color: #999;*/
/*    margin: 0 0 0 45px;*/
/*}*/

/*.testimonials .testimonial-item .quote-icon-left, .testimonials .testimonial-item .quote-icon-right {*/
/*    color: rgba(255, 255, 255, 0.25);*/
/*    font-size: 26px;*/
/*}*/

/*.testimonials .testimonial-item .quote-icon-left {*/
/*    display: inline-block;*/
/*    left: -5px;*/
/*    position: relative;*/
/*}*/

/*.testimonials .testimonial-item .quote-icon-right {*/
/*    display: inline-block;*/
/*    right: -5px;*/
/*    position: relative;*/
/*    top: 10px;*/
/*}*/

/*.testimonials .testimonial-item p {*/
/*    font-style: italic;*/
/*    margin: 0 15px 0 15px;*/
/*    padding: 20px 20px 60px 20px;*/
/*    background: rgba(255, 255, 255, 0.1);*/
/*    position: relative;*/
/*    border-radius: 6px;*/
/*    position: relative;*/
/*    z-index: 1;*/
/*}*/

/*.testimonials .swiper-pagination {*/
/*    margin-top: 20px;*/
/*    position: relative;*/
/*}*/

/*.testimonials .swiper-pagination .swiper-pagination-bullet {*/
/*    width: 12px;*/
/*    height: 12px;*/
/*    opacity: 1;*/
/*    background-color: rgba(255, 255, 255, 0.3);*/
/*}*/

/*.testimonials .swiper-pagination .swiper-pagination-bullet-active {*/
/*    background-color: #18d26e;*/
/*}*/


.carousel-root {
    width: 64% !important;
    margin: auto !important;
    margin-top: 3% !important;
}

.carousel .slide {
    background: transparent !important;
    color: white;
    height: 100%;
}

.carousel .slide img {
    width: 100px !important;
    height: 100px !important;
    border-radius: 50%;
}

.myCarousel {
    background: transparent;
    margin-top: -6%;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 6%;
    padding-bottom: 8%;
    padding-left: 5%;
    padding-right: 5%;
    /*border: 1px solid #ddd;*/
    height: 286px;
}

.carousel .control-dots {
    padding-left: 5px !important;
    outline: 0;
    bottom: 5% !important;
}

.myCarousel h3 {
    color: navajowhite;
    font-weight: 100;
    letter-spacing: 0.2px;
    margin-bottom: 4px;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 17px;
}

.myCarousel h4 {
    text-transform: uppercase;
    margin-top: 0;
    padding-top: 0;
    font-weight: 500;
    color: #007aff;
    font-size: 14px;
}

.myCarousel p {
    font-weight: 100 !important;
    line-height: 29px !important;
    text-transform: initial;
    color: #b6dc81;
    font-size: 15px;
    font-family: sans-serif;
    max-height: 67px;
}

.myCarousel p:before {
    content: "“";
    color: #aaa;
    font-size: 26px;
    font-family: monospace;
    font-weight: 100;
}

.myCarousel p:after {
    content: "”";
    color: #aaa;
    font-size: 26px;
    font-family: monospace;
    font-weight: 100;
    line-height: 0;
}

.carousel .control-dots .dot {
    box-shadow: none !important;
    background: #454545 !important;
    outline: 0;
}

.carousel.carousel-slider .control-arrow {
    background: #000 !important;
    height: 50px !important;
    position: absolute;
    top: 35% !important;
}

.App {
    text-align: center;
}

@media only screen and (max-width: 934px) {
    .carousel-root {
        outline: 0;
        width: 93% !important;
        margin: auto !important;
    }

    .carousel.carousel-slider .control-arrow {
        display: none !important;
    }

    .myCarousel {
        /*background: #fafafa;*/
        margin-top: -9%;
        width: 88%;
        margin-left: auto;
        margin-right: auto;
        padding-top: 8%;
        padding-bottom: 12.5%;
        padding-left: 5%;
        padding-right: 5%;
        /*border: 1px solid #dddddd;*/
        height: 269px;
    }

    .carousel .slide img {
        width: 24% !important;
        border-radius: 50%;
    }

    .myCarousel h3 {
        margin-top: 5px;
    }

    .navbar-mobile a {
        color: white;
    }
}