
/*--------------------------------------------------------------
# Interests
--------------------------------------------------------------*/
.interests .icon-box {
    display: flex;
    align-items: center;
    padding: 20px;
    background: rgba(255, 255, 255, 0.08);
    transition: ease-in-out 0.3s;
    margin: 3px;
}

.interests .icon-box i {
    font-size: 32px;
    padding-right: 10px;
    line-height: 1;
}

.interests .icon-box h3 {
    font-weight: 700;
    margin: 0;
    padding: 0;
    line-height: 1;
    font-size: 16px;
    color: #fff;
}

.interests .icon-box:hover {
    background: rgba(255, 255, 255, 0.12);
}