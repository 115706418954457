/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
#header {
    transition: ease-in-out 0.3s;
    position: relative;
    height: 100vh;
    display: flex;
    align-items: center;
    z-index: 997;
    overflow-y: auto;
}

#header * {
    transition: ease-in-out 0.3s;
}

#header h1 {
    font-size: 48px;
    margin: 0;
    padding: 0;
    line-height: 1;
    font-weight: 700;
    font-family: "Poppins", sans-serif;
    color:white;
}

#header h1 a, #header h1 a:hover {
    color: #fff;
    line-height: 1;
    display: inline-block;
}

#header h2 {
    font-size: 24px;
    margin-top: 20px;
    color: rgba(255, 255, 255, 0.8);
}

#header h2 span {
    color: #fff;
    border-bottom: 2px solid #18d26e;
    padding-bottom: 6px;
}

#header img {
    padding: 0;
    margin: 0;
}

#header .social-links {
    margin-top: 40px;
    display: flex;
}

#header .social-links a {
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.1);
    color: #fff;
    line-height: 1;
    margin-right: 8px;
    border-radius: 50%;
    width: 40px;
    height: 40px;
}

#header .social-links a:hover {
    background: #18d26e;
}

@media (max-width: 900px) {
    #header h1 {
        font-size: 36px;
    }
    #header h2 {
        font-size: 20px;
        line-height: 30px;
    }
    #header .social-links {
        margin-top: 15px;
    }
    #header .container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

/* Header Top */
#header.header-top {
    height: 80px;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.9);
}

#header.header-top .social-links, #header.header-top h2 {
    display: none;
}

#header.header-top h1 {
    margin-right: auto;
    font-size: 36px;
}

#header.header-top .container {
    display: flex;
    align-items: center;
}

#header.header-top .navbar {
    margin: 0;
}

@media (max-width: 768px) {
    #header.header-top {
        height: 60px;
    }
    #header.header-top h1 {
        font-size: 26px;
    }
}

/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/
/**
* Desktop Navigation
*/



.mytitle {
    color: rgb(0, 82, 59);
    background-color: rgba(169, 109, 0, 0.43);
    padding: 5px;
}

.main {
    padding: 0px 10px 0px 265px !important;
    text-align: justify;
    background-color: white;
    color: black;
    font-weight: bold;
    /*margin-top: 50px;*/
}



.navbar {
    padding: 0;
    margin-top: 30px;
}

.navbar ul {
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none;
    align-items: center;
}

.navbar li {
    position: relative;
}

.navbar li + li {
    margin-left: 30px;
}

.navbar a {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0;
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.7);
    white-space: nowrap;
    transition: 0.3s;
}

.navbar a i {
    font-size: 12px;
    line-height: 0;
    margin-left: 5px;
}

.navbar a:before {
    content: "";
    position: absolute;
    width: 0;
    height: 2px;
    bottom: -4px;
    left: 0;
    background-color: #18d26e;
    visibility: hidden;
    width: 0px;
    transition: all 0.3s ease-in-out 0s;
}

.navbar a:hover:before, .navbar li:hover > a:before, .navbar .active:before {
    visibility: visible;
    width: 25px;
}

.navbar a:hover, .navbar .active, .navbar li:hover > a {
    color: #fff;
}

/**
* Mobile Navigation
*/
.mobile-nav-toggle {
    color: #fff;
    font-size: 28px;
    cursor: pointer;
    display: none;
    line-height: 0;
    transition: 0.5s;
    position: fixed;
    right: 15px;
    top: 15px;
}

@media (max-width: 991px) {
    .mobile-nav-toggle {
        display: block;
    }
    .navbar ul {
        display: none;
    }
}

.navbar-mobile {
    position: fixed;
    overflow: hidden;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.9);
    transition: 0.3s;
    z-index: 999;
    margin-top: 0;
}

.navbar-mobile .mobile-nav-toggle {
    position: absolute;
    top: 15px;
    right: 15px;
}

.navbar-mobile ul {
    display: block;
    position: absolute;
    top: 55px;
    right: 15px;
    bottom: 45px;
    left: 15px;
    padding: 10px 0;
    overflow-y: auto;
    transition: 0.3s;
    border: 2px solid rgba(255, 255, 255, 0.2);
}

.navbar-mobile li {
    padding: 12px 20px;
}

.navbar-mobile li + li {
    margin: 0;
}

.navbar-mobile a {
    font-size: 16px;
    position: relative;
}