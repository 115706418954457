/*--------------------------------------------------------------
# Contact
--------------------------------------------------------------*/


.loading {
    /*display: none;*/
    background: rgba(255, 255, 255, 0.08);
    text-align: center;
    padding: 15px;
}

.loading:before {
    content: "";
    display: inline-block;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    margin: 0 10px -6px 0;
    border: 3px solid #18d26e;
    border-top-color: #eee;
    -webkit-animation: animate-loading 1s linear infinite;
    animation: animate-loading 1s linear infinite;
}



@keyframes animate-loading {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}