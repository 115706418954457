
/*--------------------------------------------------------------
# Resume
--------------------------------------------------------------*/
.resume .resume-title {
    font-size: 26px;
    font-weight: 700;
    margin-top: 20px;
    margin-bottom: 20px;
    color: #fff;
}

.resume .resume-item {
    padding: 0 0 20px 20px;
    margin-top: -2px;
    border-left: 2px solid rgba(255, 255, 255, 0.2);
    position: relative;
}

.resume .resume-item h4 {
    line-height: 18px;
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
    font-family: "Poppins", sans-serif;
    color: #18d26e;
    margin-bottom: 10px;
}

.resume .resume-item h5 {
    font-size: 16px;
    background: rgba(255, 255, 255, 0.15);
    padding: 5px 15px;
    display: block;
    font-weight: 600;
    margin-bottom: 10px;
}

.resume .resume-item ul {
    padding-left: 20px;
}

.resume .resume-item ul li {
    padding-bottom: 10px;
}

.resume .resume-item:last-child {
    padding-bottom: 0;
}

.resume .resume-item::before {
    content: "";
    position: absolute;
    width: 16px;
    height: 16px;
    border-radius: 50px;
    left: -9px;
    top: 0;
    background: #18d26e;
    border: 2px solid #18d26e;
}