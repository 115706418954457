/*--------------------------------------------------------------
# About
--------------------------------------------------------------*/
.about-me .content h3 {
    font-weight: 700;
    font-size: 26px;
    color: #18d26e;
}

.about-me .content ul {
    list-style: none;
    padding: 0;
}

.about-me .content ul li {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
}

.about-me .content ul strong {
    margin-right: 10px;
}

.about-me .content ul i {
    font-size: 16px;
    margin-right: 5px;
    color: #18d26e;
    line-height: 0;
}

.about-me .content p:last-child {
    margin-bottom: 0;
}