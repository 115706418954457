.mybackfooter{
    background-color: #044284;
    color: white;
    position: inherit;

    height: auto;

    color: white;
    left: 0;
    bottom: 0;
    width: 100%;


    text-align: center;
    /*padding-left: 240px;*/
}