@import url(//maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css);
body {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAYAAACpSkzOAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAALEgAACxIB0t1+/AAAABZ0RVh0Q3JlYXRpb24gVGltZQAxMC8yOS8xMiKqq3kAAAAcdEVYdFNvZnR3YXJlAEFkb2JlIEZpcmV3b3JrcyBDUzVxteM2AAABHklEQVRIib2Vyw6EIAxFW5idr///Qx9sfG3pLEyJ3tAwi5EmBqRo7vHawiEEERHS6x7MTMxMVv6+z3tPMUYSkfTM/R0fEaG2bbMv+Gc4nZzn+dN4HAcREa3r+hi3bcuu68jLskhVIlW073tWaYlQ9+F9IpqmSfq+fwskhdO/AwmUTJXrOuaRQNeRkOd5lq7rXmS5InmERKoER/QMvUAPlZDHcZRhGN4CSeGY+aHMqgcks5RrHv/eeh455x5KrMq2yHQdibDO6ncG/KZWL7M8xDyS1/MIO0NJqdULLS81X6/X6aR0nqBSJcPeZnlZrzN477NKURn2Nus8sjzmEII0TfMiyxUuxphVWjpJkbx0btUnshRihVv70Bv8ItXq6Asoi/ZiCbU6YgAAAABJRU5ErkJggg==);
}

.error-template {
    padding: 40px 15px;
    text-align: center;
}

.error-actions {
    margin-top: 15px;
    margin-bottom: 15px;
}

.error-actions .btn {
    margin-right: 10px;
}
html,body{
    /*background-image: url('http://getwallpapers.com/wallpaper/full/a/5/d/544750.jpg');*/
    background-size: cover;
    background-repeat: no-repeat;
    height: 100%;
    font-family: 'Numans', sans-serif;
}
.user_card {
    height: 400px;
    width: 350px;
    margin-top: 100px;
    margin-bottom: auto;
    background: #9c9a97d4;
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    -webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    -moz-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 5px;

}
.brand_logo_container {
    position: absolute;
    height: 170px;
    width: 170px;
    top: -75px;
    border-radius: 50%;
    background: #60a3bc;
    padding: 10px;
    text-align: center;
}
.brand_logo {
    height: 150px;
    width: 150px;
    border-radius: 50%;
    border: 2px solid white;
}
.form_container {
    margin-top: 100px;
}
.login_btn {
    width: 100%;
    background: #c0392b !important;
    color: white !important;
}
.login_btn:focus {
    box-shadow: none !important;
    outline: 0px !important;
}
.login_container {
    padding: 0 2rem;
}
.input-group-text {
    background: #c0392b !important;
    color: white !important;
    border: 0 !important;
    border-radius: 0.25rem 0 0 0.25rem !important;
}
.input_user,
.input_pass:focus {
    box-shadow: none !important;
    outline: 0px !important;
}
.custom-checkbox .custom-control-input:checked~.custom-control-label::before {
    background-color: #c0392b !important;
}
html,body{
    /*background-image: url('http://getwallpapers.com/wallpaper/full/a/5/d/544750.jpg');*/
    background-size: cover;
    background-repeat: no-repeat;
    height: 100%;
    font-family: 'Numans', sans-serif;
}
.user_card {
    height: auto;
    width: 350px;
    margin-top: 100px;
    margin-bottom: auto;
    background: #9c9a97d4;
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    -webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    -moz-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 5px;

}
.brand_logo_container {
    position: absolute;
    height: 170px;
    width: 170px;
    top: -75px;
    border-radius: 50%;
    background: #60a3bc;
    padding: 10px;
    text-align: center;
}
.brand_logo {
    height: 150px;
    width: 150px;
    border-radius: 50%;
    border: 2px solid white;
}
.form_container {
    margin-top: 100px;
}
.login_btn {
    width: 100%;
    background: #c0392b !important;
    color: white !important;
}
.login_btn:focus {
    box-shadow: none !important;
    outline: 0px !important;
}
.login_container {
    padding: 0 2rem;
}
.input-group-text {
    background: #c0392b !important;
    color: white !important;
    border: 0 !important;
    border-radius: 0.25rem 0 0 0.25rem !important;
}
.input_user,
.input_pass:focus {
    box-shadow: none !important;
    outline: 0px !important;
}
.custom-checkbox .custom-control-input:checked~.custom-control-label::before {
    background-color: #c0392b !important;
}
html,body{
    /*background-image: url('http://getwallpapers.com/wallpaper/full/a/5/d/544750.jpg');*/
    background-size: cover;
    background-repeat: no-repeat;
    height: 100%;
    font-family: 'Numans', sans-serif;
}
.user_card {
    height: auto;
    width: 350px;
    margin-top: 100px;
    margin-bottom: auto;
    background: #9c9a97d4;
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    -webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    -moz-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 5px;

}
.brand_logo_container {
    position: absolute;
    height: 170px;
    width: 170px;
    top: -75px;
    border-radius: 50%;
    background: #60a3bc;
    padding: 10px;
    text-align: center;
}
.brand_logo {
    height: 150px;
    width: 150px;
    border-radius: 50%;
    border: 2px solid white;
}
.form_container {
    margin-top: 100px;
}
.login_btn {
    width: 100%;
    background: #c0392b !important;
    color: white !important;
}
.login_btn:focus {
    box-shadow: none !important;
    outline: 0px !important;
}
.login_container {
    padding: 0 2rem;
}
.input-group-text {
    background: #c0392b !important;
    color: white !important;
    border: 0 !important;
    border-radius: 0.25rem 0 0 0.25rem !important;
}
.input_user,
.input_pass:focus {
    box-shadow: none !important;
    outline: 0px !important;
}
.custom-checkbox .custom-control-input:checked~.custom-control-label::before {
    background-color: #c0392b !important;
}
html,body{
    /*background-image: url('http://getwallpapers.com/wallpaper/full/a/5/d/544750.jpg');*/
    background-size: cover;
    background-repeat: no-repeat;
    height: 100%;
    font-family: 'Numans', sans-serif;
}
.user_card {
    height: auto;
    width: 350px;
    margin-top: 100px;
    margin-bottom: auto;
    background: #9c9a97d4;
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    -webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    -moz-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 5px;

}
.brand_logo_container {
    position: absolute;
    height: 170px;
    width: 170px;
    top: -75px;
    border-radius: 50%;
    background: #60a3bc;
    padding: 10px;
    text-align: center;
}
.brand_logo {
    height: 150px;
    width: 150px;
    border-radius: 50%;
    border: 2px solid white;
}
.form_container {
    margin-top: 100px;
}
.login_btn {
    width: 100%;
    background: #c0392b !important;
    color: white !important;
}
.login_btn:focus {
    box-shadow: none !important;
    outline: 0px !important;
}
.login_container {
    padding: 0 2rem;
}
.input-group-text {
    background: #c0392b !important;
    color: white !important;
    border: 0 !important;
    border-radius: 0.25rem 0 0 0.25rem !important;
}
.input_user,
.input_pass:focus {
    box-shadow: none !important;
    outline: 0px !important;
}
.custom-checkbox .custom-control-input:checked~.custom-control-label::before {
    background-color: #c0392b !important;
}
/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
#header {
    transition: ease-in-out 0.3s;
    position: relative;
    height: 100vh;
    display: flex;
    align-items: center;
    z-index: 997;
    overflow-y: auto;
}

#header * {
    transition: ease-in-out 0.3s;
}

#header h1 {
    font-size: 48px;
    margin: 0;
    padding: 0;
    line-height: 1;
    font-weight: 700;
    font-family: "Poppins", sans-serif;
    color:white;
}

#header h1 a, #header h1 a:hover {
    color: #fff;
    line-height: 1;
    display: inline-block;
}

#header h2 {
    font-size: 24px;
    margin-top: 20px;
    color: rgba(255, 255, 255, 0.8);
}

#header h2 span {
    color: #fff;
    border-bottom: 2px solid #18d26e;
    padding-bottom: 6px;
}

#header img {
    padding: 0;
    margin: 0;
}

#header .social-links {
    margin-top: 40px;
    display: flex;
}

#header .social-links a {
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.1);
    color: #fff;
    line-height: 1;
    margin-right: 8px;
    border-radius: 50%;
    width: 40px;
    height: 40px;
}

#header .social-links a:hover {
    background: #18d26e;
}

@media (max-width: 900px) {
    #header h1 {
        font-size: 36px;
    }
    #header h2 {
        font-size: 20px;
        line-height: 30px;
    }
    #header .social-links {
        margin-top: 15px;
    }
    #header .container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

/* Header Top */
#header.header-top {
    height: 80px;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.9);
}

#header.header-top .social-links, #header.header-top h2 {
    display: none;
}

#header.header-top h1 {
    margin-right: auto;
    font-size: 36px;
}

#header.header-top .container {
    display: flex;
    align-items: center;
}

#header.header-top .navbar {
    margin: 0;
}

@media (max-width: 768px) {
    #header.header-top {
        height: 60px;
    }
    #header.header-top h1 {
        font-size: 26px;
    }
}

/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/
/**
* Desktop Navigation
*/



.mytitle {
    color: rgb(0, 82, 59);
    background-color: rgba(169, 109, 0, 0.43);
    padding: 5px;
}

.main {
    padding: 0px 10px 0px 265px !important;
    text-align: justify;
    background-color: white;
    color: black;
    font-weight: bold;
    /*margin-top: 50px;*/
}



.navbar {
    padding: 0;
    margin-top: 30px;
}

.navbar ul {
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none;
    align-items: center;
}

.navbar li {
    position: relative;
}

.navbar li + li {
    margin-left: 30px;
}

.navbar a {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0;
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.7);
    white-space: nowrap;
    transition: 0.3s;
}

.navbar a i {
    font-size: 12px;
    line-height: 0;
    margin-left: 5px;
}

.navbar a:before {
    content: "";
    position: absolute;
    width: 0;
    height: 2px;
    bottom: -4px;
    left: 0;
    background-color: #18d26e;
    visibility: hidden;
    width: 0px;
    transition: all 0.3s ease-in-out 0s;
}

.navbar a:hover:before, .navbar li:hover > a:before, .navbar .active:before {
    visibility: visible;
    width: 25px;
}

.navbar a:hover, .navbar .active, .navbar li:hover > a {
    color: #fff;
}

/**
* Mobile Navigation
*/
.mobile-nav-toggle {
    color: #fff;
    font-size: 28px;
    cursor: pointer;
    display: none;
    line-height: 0;
    transition: 0.5s;
    position: fixed;
    right: 15px;
    top: 15px;
}

@media (max-width: 991px) {
    .mobile-nav-toggle {
        display: block;
    }
    .navbar ul {
        display: none;
    }
}

.navbar-mobile {
    position: fixed;
    overflow: hidden;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.9);
    transition: 0.3s;
    z-index: 999;
    margin-top: 0;
}

.navbar-mobile .mobile-nav-toggle {
    position: absolute;
    top: 15px;
    right: 15px;
}

.navbar-mobile ul {
    display: block;
    position: absolute;
    top: 55px;
    right: 15px;
    bottom: 45px;
    left: 15px;
    padding: 10px 0;
    overflow-y: auto;
    transition: 0.3s;
    border: 2px solid rgba(255, 255, 255, 0.2);
}

.navbar-mobile li {
    padding: 12px 20px;
}

.navbar-mobile li + li {
    margin: 0;
}

.navbar-mobile a {
    font-size: 16px;
    position: relative;
}
.loader {

    padding: 5px;
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid blue;
    border-right: 16px solid green;
    border-bottom: 16px solid red;
    border-left: 16px solid pink;
    width: 120px;
    height: 120px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
    margin: auto;
}

@-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
    0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}
.mybackfooter{
    background-color: #044284;
    color: white;
    position: inherit;

    height: auto;

    color: white;
    left: 0;
    bottom: 0;
    width: 100%;


    text-align: center;
    /*padding-left: 240px;*/
}
@-webkit-keyframes swing {
    0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    10% {
        -webkit-transform: rotate(10deg);
                transform: rotate(10deg);
    }
    30% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    40% {
        -webkit-transform: rotate(-10deg);
                transform: rotate(-10deg);
    }
    50% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    60% {
        -webkit-transform: rotate(5deg);
                transform: rotate(5deg);
    }
    70% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    80% {
        -webkit-transform: rotate(-5deg);
                transform: rotate(-5deg);
    }
    100% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
}

@keyframes swing {
    0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    10% {
        -webkit-transform: rotate(10deg);
                transform: rotate(10deg);
    }
    30% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    40% {
        -webkit-transform: rotate(-10deg);
                transform: rotate(-10deg);
    }
    50% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    60% {
        -webkit-transform: rotate(5deg);
                transform: rotate(5deg);
    }
    70% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    80% {
        -webkit-transform: rotate(-5deg);
                transform: rotate(-5deg);
    }
    100% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
}

@-webkit-keyframes sonar {
    0% {
        -webkit-transform: scale(0.9);
                transform: scale(0.9);
        opacity: 1;
    }
    100% {
        -webkit-transform: scale(2);
                transform: scale(2);
        opacity: 0;
    }
}

@keyframes sonar {
    0% {
        -webkit-transform: scale(0.9);
                transform: scale(0.9);
        opacity: 1;
    }
    100% {
        -webkit-transform: scale(2);
                transform: scale(2);
        opacity: 0;
    }
}
body {
    font-size: 0.9rem;
}
.page-wrapper .sidebar-wrapper,
.sidebar-wrapper .sidebar-brand > a,
.sidebar-wrapper .sidebar-dropdown > a:after,
.sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu li a:before,
.sidebar-wrapper ul li a i,
.page-wrapper .page-content,
.sidebar-wrapper .sidebar-search input.search-menu,
.sidebar-wrapper .sidebar-search .input-group-text,
.sidebar-wrapper .sidebar-menu ul li a,
#show-sidebar,
#close-sidebar {
    transition: all 0.3s ease;
}

/*----------------page-wrapper----------------*/

.page-wrapper {
    /*height: 100vh;*/
}

.page-wrapper .theme {
    width: 40px;
    height: 40px;
    display: inline-block;
    border-radius: 4px;
    margin: 2px;
}

.page-wrapper .theme.chiller-theme {
    background: #1e2229;
}

/*----------------toggeled sidebar----------------*/

.page-wrapper.toggled .sidebar-wrapper {
    left: 0px;
}

@media screen and (min-width: 768px) {
    .page-wrapper.toggled .page-content {
        padding-left: 300px;
    }
}
/*----------------show sidebar button----------------*/
#show-sidebar {
    position: fixed;
    left: 0;
    top: 10px;
    border-radius: 0 4px 4px 0px;
    width: 35px;
    transition-delay: 0.3s;
}
.page-wrapper.toggled #show-sidebar {
    left: -40px;
}
/*----------------sidebar-wrapper----------------*/

.sidebar-wrapper {
    width: 260px;
    height: 100%;
    max-height: 100%;
    position: fixed;
    top: 0;
    left: -300px;
    z-index: 999;
}

.sidebar-wrapper ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.sidebar-wrapper a {
    text-decoration: none;
}

/*----------------sidebar-content----------------*/

.sidebar-content {
    max-height: calc(100% - 30px);
    height: calc(100% - 30px);
    overflow-y: auto;
    position: relative;
}

.sidebar-content.desktop {
    overflow-y: hidden;
}

/*--------------------sidebar-brand----------------------*/

.sidebar-wrapper .sidebar-brand {
    padding: 10px 20px;
    display: flex;
    align-items: center;
}

.sidebar-wrapper .sidebar-brand > a {
    text-transform: uppercase;
    font-weight: bold;
    flex-grow: 1;
}

.sidebar-wrapper .sidebar-brand #close-sidebar {
    cursor: pointer;
    font-size: 20px;
}
/*--------------------sidebar-header----------------------*/

.sidebar-wrapper .sidebar-header {
    padding: 20px;
    overflow: hidden;
}

.sidebar-wrapper .sidebar-header .user-pic {
    float: left;
    width: 60px;
    padding: 2px;
    border-radius: 12px;
    margin-right: 15px;
    overflow: hidden;
}

.sidebar-wrapper .sidebar-header .user-pic img {
    object-fit: cover;
    height: 60px;
    width: 100%;
}

.sidebar-wrapper .sidebar-header .user-info {
    float: left;
}

.sidebar-wrapper .sidebar-header .user-info > span {
    display: block;
}

.sidebar-wrapper .sidebar-header .user-info .user-role {
    font-size: 12px;
}

.sidebar-wrapper .sidebar-header .user-info .user-status {
    font-size: 11px;
    margin-top: 4px;
}

.sidebar-wrapper .sidebar-header .user-info .user-status i {
    font-size: 8px;
    margin-right: 4px;
    color: #5cb85c;
}

/*-----------------------sidebar-search------------------------*/

.sidebar-wrapper .sidebar-search > div {
    padding: 10px 20px;
}

/*----------------------sidebar-menu-------------------------*/

.sidebar-wrapper .sidebar-menu {
    padding-bottom: 10px;
}

.sidebar-wrapper .sidebar-menu .header-menu span {
    font-weight: bold;
    font-size: 14px;
    padding: 15px 20px 5px 20px;
    display: inline-block;
}

.sidebar-wrapper .sidebar-menu ul li a {
    display: inline-block;
    width: 100%;
    text-decoration: none;
    position: relative;
    padding: 8px 30px 8px 20px;
}

.sidebar-wrapper .sidebar-menu ul li a i {
    margin-right: 10px;
    font-size: 12px;
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    border-radius: 4px;
}

.sidebar-wrapper .sidebar-menu ul li a:hover > i::before {
    display: inline-block;
    -webkit-animation: swing ease-in-out 0.5s 1 alternate;
            animation: swing ease-in-out 0.5s 1 alternate;
}

.sidebar-wrapper .sidebar-menu .sidebar-dropdown > a:after {
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    content: "\f105";
    font-style: normal;
    display: inline-block;
    font-style: normal;
    -webkit-font-feature-settings: normal;
            font-feature-settings: normal;
    font-variant: normal;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    background: 0 0;
    position: absolute;
    right: 15px;
    top: 14px;
}

.sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu ul {
    padding: 5px 0;
}

.sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu li {
    padding-left: 25px;
    font-size: 13px;
}

.sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu li a:before {
    content: "\f111";
    font-family: "Font Awesome 5 Free";
    font-weight: 400;
    font-style: normal;
    display: inline-block;
    text-align: center;
    text-decoration: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin-right: 10px;
    font-size: 8px;
}

.sidebar-wrapper .sidebar-menu ul li a span.label,
.sidebar-wrapper .sidebar-menu ul li a span.badge {
    float: right;
    margin-top: 8px;
    margin-left: 5px;
}

.sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu li a .badge,
.sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu li a .label {
    float: right;
    margin-top: 0px;
}

.sidebar-wrapper .sidebar-menu .sidebar-submenu {
    display: none;
}

.sidebar-wrapper .sidebar-menu .sidebar-dropdown.active > a:after {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    right: 17px;
}

/*--------------------------side-footer------------------------------*/

.sidebar-footer {
    position: absolute;
    width: 100%;
    bottom: 0;
    display: flex;
}

.sidebar-footer > a {
    flex-grow: 1;
    text-align: center;
    height: 30px;
    line-height: 30px;
    position: relative;
}

.sidebar-footer > a .notification {
    position: absolute;
    top: 0;
}

.badge-sonar {
    display: inline-block;
    background: #980303;
    border-radius: 50%;
    height: 8px;
    width: 8px;
    position: absolute;
    top: 0;
}

.badge-sonar:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    border: 2px solid #980303;
    opacity: 0;
    border-radius: 50%;
    width: 100%;
    height: 100%;
    -webkit-animation: sonar 1.5s infinite;
            animation: sonar 1.5s infinite;
}

/*--------------------------page-content-----------------------------*/

.page-wrapper .page-content {
    display: inline-block;
    width: 100%;
    padding-left: 0px;
    padding-top: 20px;
}

.page-wrapper .page-content > div {
    padding: 20px 40px;
}

.page-wrapper .page-content {
    overflow-x: hidden;
}

/*------scroll bar---------------------*/

::-webkit-scrollbar {
    width: 5px;
    height: 7px;
}
::-webkit-scrollbar-button {
    width: 0px;
    height: 0px;
}
::-webkit-scrollbar-thumb {
    background: #525965;
    border: 0px none #ffffff;
    border-radius: 0px;
}
::-webkit-scrollbar-thumb:hover {
    background: #525965;
}
::-webkit-scrollbar-thumb:active {
    background: #525965;
}
::-webkit-scrollbar-track {
    background: transparent;
    border: 0px none #ffffff;
    border-radius: 50px;
}
::-webkit-scrollbar-track:hover {
    background: transparent;
}
::-webkit-scrollbar-track:active {
    background: transparent;
}
::-webkit-scrollbar-corner {
    background: transparent;
}


/*-----------------------------chiller-theme-------------------------------------------------*/

.chiller-theme .sidebar-wrapper {
    background: #31353D;
}

.chiller-theme .sidebar-wrapper .sidebar-header,
.chiller-theme .sidebar-wrapper .sidebar-search,
.chiller-theme .sidebar-wrapper .sidebar-menu {
    border-top: 1px solid #3a3f48;
}

.chiller-theme .sidebar-wrapper .sidebar-search input.search-menu,
.chiller-theme .sidebar-wrapper .sidebar-search .input-group-text {
    border-color: transparent;
    box-shadow: none;
}

.chiller-theme .sidebar-wrapper .sidebar-header .user-info .user-role,
.chiller-theme .sidebar-wrapper .sidebar-header .user-info .user-status,
.chiller-theme .sidebar-wrapper .sidebar-search input.search-menu,
.chiller-theme .sidebar-wrapper .sidebar-search .input-group-text,
.chiller-theme .sidebar-wrapper .sidebar-brand>a,
.chiller-theme .sidebar-wrapper .sidebar-menu ul li a,
.chiller-theme .sidebar-footer>a {
    color: #818896;
}

.chiller-theme .sidebar-wrapper .sidebar-menu ul li:hover>a,
.chiller-theme .sidebar-wrapper .sidebar-menu .sidebar-dropdown.active>a,
.chiller-theme .sidebar-wrapper .sidebar-header .user-info,
.chiller-theme .sidebar-wrapper .sidebar-brand>a:hover,
.chiller-theme .sidebar-footer>a:hover i {
    color: #b8bfce;
}

.page-wrapper.chiller-theme.toggled #close-sidebar {
    color: #bdbdbd;
}

.page-wrapper.chiller-theme.toggled #close-sidebar:hover {
    color: #ffffff;
}

.chiller-theme .sidebar-wrapper ul li:hover a i,
.chiller-theme .sidebar-wrapper .sidebar-dropdown .sidebar-submenu li a:hover:before,
.chiller-theme .sidebar-wrapper .sidebar-search input.search-menu:focus+span,
.chiller-theme .sidebar-wrapper .sidebar-menu .sidebar-dropdown.active a i {
    color: #16c7ff;
    text-shadow:0px 0px 10px rgba(22, 199, 255, 0.5);
}

.chiller-theme .sidebar-wrapper .sidebar-menu ul li a i,
.chiller-theme .sidebar-wrapper .sidebar-menu .sidebar-dropdown div,
.chiller-theme .sidebar-wrapper .sidebar-search input.search-menu,
.chiller-theme .sidebar-wrapper .sidebar-search .input-group-text {
    background: #3a3f48;
}

.chiller-theme .sidebar-wrapper .sidebar-menu .header-menu span {
    color: #6c7b88;
}

.chiller-theme .sidebar-footer {
    background: #3a3f48;
    box-shadow: 0px -1px 5px #282c33;
    border-top: 1px solid #464a52;
}

.chiller-theme .sidebar-footer>a:first-child {
    border-left: none;
}

.chiller-theme .sidebar-footer>a:last-child {
    border-right: none;
}

body::before {

    /*background: none;*/

}

.bnavbar{
    padding:0;
    display: flex;
}

.bnavbar a {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0;
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.7);
    white-space: nowrap;
    transition: 0.3s;
}
.bnavbar li + li {
    margin-left: 30px;
}
.bnavbar li {
    position: relative;
}

.nav_bar {
    padding-left: 260px;
    /*height: 40px;*/
    width: 100%;
    background-color: royalblue;
}

.navbar-icon-top .navbar-nav .nav-link > .fa {
    position: relative;
    width: 36px;
    font-size: 24px;
}

.navbar-icon-top .navbar-nav .nav-link > .fa > .badge {
    font-size: 0.75rem;
    position: absolute;
    right: 0;
    font-family: sans-serif;
}

.navbar-icon-top .navbar-nav .nav-link > .fa {
    top: 3px;
    line-height: 12px;
}

.navbar-icon-top .navbar-nav .nav-link > .fa > .badge {
    top: -10px;
}

@media (min-width: 576px) {
    .navbar-icon-top.navbar-expand-sm .navbar-nav .nav-link {
        text-align: center;
        display: table-cell;
        height: 70px;
        vertical-align: middle;
        padding-top: 0;
        padding-bottom: 0;
    }

    .navbar-icon-top.navbar-expand-sm .navbar-nav .nav-link > .fa {
        display: block;
        width: 48px;
        margin: 2px auto 4px auto;
        top: 0;
        line-height: 24px;
    }

    .navbar-icon-top.navbar-expand-sm .navbar-nav .nav-link > .fa > .badge {
        top: -7px;
    }
}

@media (min-width: 768px) {
    .navbar-icon-top.navbar-expand-md .navbar-nav .nav-link {
        text-align: center;
        display: table-cell;
        height: 70px;
        vertical-align: middle;
        padding-top: 0;
        padding-bottom: 0;
    }

    .navbar-icon-top.navbar-expand-md .navbar-nav .nav-link > .fa {
        display: block;
        width: 48px;
        margin: 2px auto 4px auto;
        top: 0;
        line-height: 24px;
    }

    .navbar-icon-top.navbar-expand-md .navbar-nav .nav-link > .fa > .badge {
        top: -7px;
    }
}

@media (min-width: 992px) {
    .navbar-icon-top.navbar-expand-lg .navbar-nav .nav-link {
        text-align: center;
        display: table-cell;
        height: 70px;
        vertical-align: middle;
        padding-top: 0;
        padding-bottom: 0;
    }

    .navbar-icon-top.navbar-expand-lg .navbar-nav .nav-link > .fa {
        display: block;
        width: 48px;
        margin: 2px auto 4px auto;
        top: 0;
        line-height: 24px;
    }

    .navbar-icon-top.navbar-expand-lg .navbar-nav .nav-link > .fa > .badge {
        top: -7px;
    }
}

@media (min-width: 1200px) {
    .navbar-icon-top.navbar-expand-xl .navbar-nav .nav-link {
        text-align: center;
        display: table-cell;
        height: 70px;
        vertical-align: middle;
        padding-top: 0;
        padding-bottom: 0;
    }

    .navbar-icon-top.navbar-expand-xl .navbar-nav .nav-link > .fa {
        display: block;
        width: 48px;
        margin: 2px auto 4px auto;
        top: 0;
        line-height: 24px;
    }

    .navbar-icon-top.navbar-expand-xl .navbar-nav .nav-link > .fa > .badge {
        top: -7px;
    }
}

.pagination {
    margin: 15px auto;
    display: flex;
    list-style: none;
    outline: none;
}

.pagination > .active > a {
    background-color: #47ccde;
    border-color: #47ccde;
    color: #fff;
}
.margin-auto{
    margin: auto;
}

.pagination > li > a {
    border: 1px solid #47ccde;
    padding: 5px 10px;
    outline: none;
    cursor: pointer;
}

.pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus {
    background-color: #47ccde;
    border-color: #47ccde;
    outline: none;
}

.pagination > li > a, .pagination > li > span {
    color: #47ccde
}

.pagination > li:first-child > a, .pagination > li:first-child > span, .pagination > li:last-child > a, .pagination > li:last-child > span {
    border-radius: unset
}
.form_testimonial_component_label-color__m1s1N{
    color: black!important;
    font-weight: bold;
}
/*--------------------------------------------------------------
# Contact
--------------------------------------------------------------*/


.loading {
    /*display: none;*/
    background: rgba(255, 255, 255, 0.08);
    text-align: center;
    padding: 15px;
}

.loading:before {
    content: "";
    display: inline-block;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    margin: 0 10px -6px 0;
    border: 3px solid #18d26e;
    border-top-color: #eee;
    -webkit-animation: animate-loading 1s linear infinite;
    animation: animate-loading 1s linear infinite;
}



@-webkit-keyframes animate-loading {
    0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}



@keyframes animate-loading {
    0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}
.pagination {
    margin: 15px auto;
    display: flex;
    list-style: none;
    outline: none;
}

.pagination > .active > a {
    background-color: #47ccde;
    border-color: #47ccde;
    color: #fff;
}
.margin-auto{
    margin: auto;
}

.pagination > li > a {
    border: 1px solid #47ccde;
    padding: 5px 10px;
    outline: none;
    cursor: pointer;
}

.pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus {
    background-color: #47ccde;
    border-color: #47ccde;
    outline: none;
}

.pagination > li > a, .pagination > li > span {
    color: #47ccde
}

.pagination > li:first-child > a, .pagination > li:first-child > span, .pagination > li:last-child > a, .pagination > li:last-child > span {
    border-radius: unset
}
.Card_card__1m44e {
  background: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 10px;
}

.MyModal_backdrop__1jBuF {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 10;
  background: rgba(0, 0, 0, 0.75);
}

.MyModal_modal__20XG1 {
  position: fixed;
  top: 5vh;
  left: 10%;
  width: 80%;
  z-index: 9999;
  overflow: hidden;
}

.MyModal_header__3OM8p {
  background: #4f005f;
  padding: 1rem;
}

.MyModal_header__3OM8p h2 {
  margin: 0;
  color: white;
}

.MyModal_content__dNFOp {
  padding: 1rem;
}

.MyModal_actions__2E9lv {
  padding: 1rem;
  display: flex;
  justify-content: flex-end;
}

@media (min-width: 768px) {
  .MyModal_modal__20XG1 {
    left: calc(50% - 20rem);
    width: 40rem;
  }
}

/*--------------------------------------------------------------
# About
--------------------------------------------------------------*/
.about-me .content h3 {
    font-weight: 700;
    font-size: 26px;
    color: #18d26e;
}

.about-me .content ul {
    list-style: none;
    padding: 0;
}

.about-me .content ul li {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
}

.about-me .content ul strong {
    margin-right: 10px;
}

.about-me .content ul i {
    font-size: 16px;
    margin-right: 5px;
    color: #18d26e;
    line-height: 0;
}

.about-me .content p:last-child {
    margin-bottom: 0;
}
/*--------------------------------------------------------------
# Contact
--------------------------------------------------------------*/


.loading {
    /*display: none;*/
    background: rgba(255, 255, 255, 0.08);
    text-align: center;
    padding: 15px;
    margin-top: 12px;
}

.loading:before {
    content: "";
    display: inline-block;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    margin: 0 10px -6px 0;
    border: 3px solid #d26c18;
    border-top-color: rgba(255, 255, 255, 0.671);
    -webkit-animation: animate-loading 1s linear infinite;
    animation: animate-loading 1s linear infinite;
}



@-webkit-keyframes animate-loading {
    0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}



@keyframes animate-loading {
    0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}
/*--------------------------------------------------------------
# Counts
--------------------------------------------------------------*/
.counts {
    padding: 70px 0 60px;
}

.counts .count-box {
    padding: 30px 30px 25px 30px;
    width: 100%;
    position: relative;
    text-align: center;
    background: rgba(255, 255, 255, 0.08);
    margin: 20px;
}

.counts .count-box i {
    position: absolute;
    top: -25px;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    font-size: 24px;
    background: rgba(255, 255, 255, 0.1);
    padding: 12px;
    color: #18d26e;
    border-radius: 50px;
    line-height: 0;
}

.counts .count-box span {
    font-size: 36px;
    display: block;
    font-weight: 600;
    color: #fff;
}

.counts .count-box p {
    padding: 0;
    margin: 0;
    font-family: "Raleway", sans-serif;
    font-size: 14px;
}
/*--------------------------------------------------------------
# Skills
--------------------------------------------------------------*/
.skills .progress {
    height: 60px;
    display: block;
    background: none;
    border-radius: 0;
}

.skills .progress .skill {
    padding: 10px 0;
    margin: 0;
    text-transform: uppercase;
    display: block;
    font-weight: 600;
    font-family: "Poppins", sans-serif;
    color: #fff;
}

.skills .progress .skill .val {
    float: right;
    font-style: normal;
}

.skills .progress-bar-wrap {
    background: rgba(255, 255, 255, 0.2);
}

.skills .progress-bar {
    width: 1px;
    height: 10px;
    transition: .9s;
    background-color: #18d26e;
}

.skillbar-percent{
 font-size:20px !important;
    color:rgb(34 20 20);
}

/*--------------------------------------------------------------
# Interests
--------------------------------------------------------------*/
.interests .icon-box {
    display: flex;
    align-items: center;
    padding: 20px;
    background: rgba(255, 255, 255, 0.08);
    transition: ease-in-out 0.3s;
    margin: 3px;
}

.interests .icon-box i {
    font-size: 32px;
    padding-right: 10px;
    line-height: 1;
}

.interests .icon-box h3 {
    font-weight: 700;
    margin: 0;
    padding: 0;
    line-height: 1;
    font-size: 16px;
    color: #fff;
}

.interests .icon-box:hover {
    background: rgba(255, 255, 255, 0.12);
}
/*!*--------------------------------------------------------------*/
/*# Testimonials*/
/*--------------------------------------------------------------*!*/
/*.testimonials .testimonial-item {*/
/*    box-sizing: content-box;*/
/*    min-height: 320px;*/
/*}*/

/*.testimonials .testimonial-item .testimonial-img {*/
/*    width: 90px;*/
/*    border-radius: 50%;*/
/*    margin: -40px 0 0 40px;*/
/*    position: relative;*/
/*    z-index: 2;*/
/*    border: 6px solid rgba(255, 255, 255, 0.12);*/
/*}*/

/*.testimonials .testimonial-item h3 {*/
/*    font-size: 18px;*/
/*    font-weight: bold;*/
/*    margin: 10px 0 5px 45px;*/
/*    color: #fff;*/
/*}*/

/*.testimonials .testimonial-item h4 {*/
/*    font-size: 14px;*/
/*    color: #999;*/
/*    margin: 0 0 0 45px;*/
/*}*/

/*.testimonials .testimonial-item .quote-icon-left, .testimonials .testimonial-item .quote-icon-right {*/
/*    color: rgba(255, 255, 255, 0.25);*/
/*    font-size: 26px;*/
/*}*/

/*.testimonials .testimonial-item .quote-icon-left {*/
/*    display: inline-block;*/
/*    left: -5px;*/
/*    position: relative;*/
/*}*/

/*.testimonials .testimonial-item .quote-icon-right {*/
/*    display: inline-block;*/
/*    right: -5px;*/
/*    position: relative;*/
/*    top: 10px;*/
/*}*/

/*.testimonials .testimonial-item p {*/
/*    font-style: italic;*/
/*    margin: 0 15px 0 15px;*/
/*    padding: 20px 20px 60px 20px;*/
/*    background: rgba(255, 255, 255, 0.1);*/
/*    position: relative;*/
/*    border-radius: 6px;*/
/*    position: relative;*/
/*    z-index: 1;*/
/*}*/

/*.testimonials .swiper-pagination {*/
/*    margin-top: 20px;*/
/*    position: relative;*/
/*}*/

/*.testimonials .swiper-pagination .swiper-pagination-bullet {*/
/*    width: 12px;*/
/*    height: 12px;*/
/*    opacity: 1;*/
/*    background-color: rgba(255, 255, 255, 0.3);*/
/*}*/

/*.testimonials .swiper-pagination .swiper-pagination-bullet-active {*/
/*    background-color: #18d26e;*/
/*}*/


.carousel-root {
    width: 64% !important;
    margin: auto !important;
    margin-top: 3% !important;
}

.carousel .slide {
    background: transparent !important;
    color: white;
    height: 100%;
}

.carousel .slide img {
    width: 100px !important;
    height: 100px !important;
    border-radius: 50%;
}

.myCarousel {
    background: transparent;
    margin-top: -6%;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 6%;
    padding-bottom: 8%;
    padding-left: 5%;
    padding-right: 5%;
    /*border: 1px solid #ddd;*/
    height: 286px;
}

.carousel .control-dots {
    padding-left: 5px !important;
    outline: 0;
    bottom: 5% !important;
}

.myCarousel h3 {
    color: navajowhite;
    font-weight: 100;
    letter-spacing: 0.2px;
    margin-bottom: 4px;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 17px;
}

.myCarousel h4 {
    text-transform: uppercase;
    margin-top: 0;
    padding-top: 0;
    font-weight: 500;
    color: #007aff;
    font-size: 14px;
}

.myCarousel p {
    font-weight: 100 !important;
    line-height: 29px !important;
    text-transform: none;
    text-transform: initial;
    color: #b6dc81;
    font-size: 15px;
    font-family: sans-serif;
    max-height: 67px;
}

.myCarousel p:before {
    content: "“";
    color: #aaa;
    font-size: 26px;
    font-family: monospace;
    font-weight: 100;
}

.myCarousel p:after {
    content: "”";
    color: #aaa;
    font-size: 26px;
    font-family: monospace;
    font-weight: 100;
    line-height: 0;
}

.carousel .control-dots .dot {
    box-shadow: none !important;
    background: #454545 !important;
    outline: 0;
}

.carousel.carousel-slider .control-arrow {
    background: #000 !important;
    height: 50px !important;
    position: absolute;
    top: 35% !important;
}

.App {
    text-align: center;
}

@media only screen and (max-width: 934px) {
    .carousel-root {
        outline: 0;
        width: 93% !important;
        margin: auto !important;
    }

    .carousel.carousel-slider .control-arrow {
        display: none !important;
    }

    .myCarousel {
        /*background: #fafafa;*/
        margin-top: -9%;
        width: 88%;
        margin-left: auto;
        margin-right: auto;
        padding-top: 8%;
        padding-bottom: 12.5%;
        padding-left: 5%;
        padding-right: 5%;
        /*border: 1px solid #dddddd;*/
        height: 269px;
    }

    .carousel .slide img {
        width: 24% !important;
        border-radius: 50%;
    }

    .myCarousel h3 {
        margin-top: 5px;
    }

    .navbar-mobile a {
        color: white;
    }
}

/*--------------------------------------------------------------
# Resume
--------------------------------------------------------------*/
.resume .resume-title {
    font-size: 26px;
    font-weight: 700;
    margin-top: 20px;
    margin-bottom: 20px;
    color: #fff;
}

.resume .resume-item {
    padding: 0 0 20px 20px;
    margin-top: -2px;
    border-left: 2px solid rgba(255, 255, 255, 0.2);
    position: relative;
}

.resume .resume-item h4 {
    line-height: 18px;
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
    font-family: "Poppins", sans-serif;
    color: #18d26e;
    margin-bottom: 10px;
}

.resume .resume-item h5 {
    font-size: 16px;
    background: rgba(255, 255, 255, 0.15);
    padding: 5px 15px;
    display: block;
    font-weight: 600;
    margin-bottom: 10px;
}

.resume .resume-item ul {
    padding-left: 20px;
}

.resume .resume-item ul li {
    padding-bottom: 10px;
}

.resume .resume-item:last-child {
    padding-bottom: 0;
}

.resume .resume-item::before {
    content: "";
    position: absolute;
    width: 16px;
    height: 16px;
    border-radius: 50px;
    left: -9px;
    top: 0;
    background: #18d26e;
    border: 2px solid #18d26e;
}
/*--------------------------------------------------------------
# Services
--------------------------------------------------------------*/
.services .icon-box {
    width: 100%;
    text-align: center;
    background: rgba(204, 204, 204, 0.1);
    padding: 80px 20px;
    transition: all ease-in-out 0.3s;
}

.services .icon-box .icon {
    margin: 0 auto;
    width: 64px;
    height: 64px;
    background: #18d26e;
    border-radius: 5px;
    transition: all .3s ease-out 0s;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
}

.services .icon-box .icon i {
    color: #fff;
    font-size: 28px;
}

.services .icon-box .icon::before {
    position: absolute;
    content: '';
    left: -8px;
    top: -8px;
    height: 100%;
    width: 100%;
    background: rgba(255, 255, 255, 0.15);
    border-radius: 5px;
    transition: all .3s ease-out 0s;
    -webkit-transform: translateZ(-1px);
            transform: translateZ(-1px);
}

.services .icon-box h4 {
    font-weight: 700;
    margin-bottom: 15px;
    font-size: 24px;
}

.services .icon-box h4 a {
    color: #fff;
}

.services .icon-box p {
    line-height: 24px;
    font-size: 14px;
    margin-bottom: 0;
}

.services .icon-box:hover {
    background: #18d26e;
    border-color: #18d26e;
}

.services .icon-box:hover .icon {
    background: #fff;
}

.services .icon-box:hover .icon i {
    color: #18d26e;
}

.services .icon-box:hover .icon::before {
    background: #35e888;
}

.services .icon-box:hover h4 a, .services .icon-box:hover p {
    color: #fff;
}

/*--------------------------------------------------------------
# Portfolio
--------------------------------------------------------------*/
.portfolio .portfolio-item {
    margin-bottom: 30px;
}

.portfolio #portfolio-flters {
    padding: 0;
    margin: 0 auto 15px auto;
    list-style: none;
    text-align: center;
    border-radius: 50px;
    padding: 2px 15px;
}

.portfolio #portfolio-flters li {
    cursor: pointer;
    display: inline-block;
    padding: 8px 16px 10px 16px;
    font-size: 14px;
    font-weight: 600;
    line-height: 1;
    text-transform: uppercase;
    color: #fff;
    background: rgba(255, 255, 255, 0.1);
    margin: 0 3px 10px 3px;
    transition: all 0.3s ease-in-out;
    border-radius: 4px;
}

.portfolio #portfolio-flters li:hover, .portfolio #portfolio-flters li.filter-active {
    background: #18d26e;
}

.portfolio #portfolio-flters li:last-child {
    margin-right: 0;
}

.portfolio .portfolio-wrap {
    transition: 0.3s;
    position: relative;
    overflow: hidden;
    z-index: 1;
    background: rgba(0, 0, 0, 0.6);
}

.portfolio .portfolio-wrap::before {
    content: "";
    background: rgba(0, 0, 0, 0.6);
    position: absolute;
    left: 30px;
    right: 30px;
    top: 30px;
    bottom: 30px;
    transition: all ease-in-out 0.3s;
    z-index: 2;
    opacity: 0;
}

.portfolio .portfolio-wrap .portfolio-info {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: center;
    z-index: 3;
    transition: all ease-in-out 0.3s;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.portfolio .portfolio-wrap .portfolio-info::before {
    display: block;
    content: "";
    width: 48px;
    height: 48px;
    position: absolute;
    top: 35px;
    left: 35px;
    border-top: 3px solid #fff;
    border-left: 3px solid #fff;
    transition: all 0.5s ease 0s;
    z-index: 9994;
}

.portfolio .portfolio-wrap .portfolio-info::after {
    display: block;
    content: "";
    width: 48px;
    height: 48px;
    position: absolute;
    bottom: 35px;
    right: 35px;
    border-bottom: 3px solid #fff;
    border-right: 3px solid #fff;
    transition: all 0.5s ease 0s;
    z-index: 9994;
}

.portfolio .portfolio-wrap .portfolio-info h4 {
    font-size: 25px;
    color: #efefef;
    font-weight: 600;
}

.portfolio .portfolio-wrap .portfolio-info p {
    color: #af96af;
    font-size: 18px;
    text-transform: uppercase;
    padding: 0;
    margin: 0;
}

.portfolio .portfolio-wrap .portfolio-links {
    text-align: center;
    z-index: 4;
}

.portfolio .portfolio-wrap .portfolio-links a {
    color: #000000;
    margin: 0 2px;
    font-size: 28px;
    display: inline-block;
    transition: 0.3s;
}

.portfolio .portfolio-wrap .portfolio-links button {
    color: #000000;
    margin: 0 2px;
    font-size: 28px;
    display: inline-block;
    transition: 0.3s;
}

.portfolio .portfolio-wrap .portfolio-links a:hover {
    color: #63eda3;
}

.portfolio .portfolio-wrap .portfolio-links button:hover {
    color: #63eda3;
}

.portfolio .portfolio-wrap:hover::before {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 1;
}

.portfolio .portfolio-wrap:hover .portfolio-info {
    opacity: 1;
}

.portfolio .portfolio-wrap:hover .portfolio-info::before {
    top: 15px;
    left: 15px;
}

.portfolio .portfolio-wrap:hover .portfolio-info::after {
    bottom: 15px;
    right: 15px;
}
/*--------------------------------------------------------------
# Contact
--------------------------------------------------------------*/

.error{
    color:red;
    font-weight: bold;
}
.contact .info-box {
    color: #444444;
    padding: 20px;
    width: 100%;
    background: rgba(255, 255, 255, 0.08);
}

.contact .info-box i.bx {
    font-size: 24px;
    color: #18d26e;
    border-radius: 50%;
    padding: 14px;
    float: left;
    background: rgba(255, 255, 255, 0.1);
}

.contact .info-box h3 {
    font-size: 20px;
    color: rgba(255, 255, 255, 0.5);
    font-weight: 700;
    margin: 10px 0 8px 68px;
}

.contact .info-box p {
    padding: 0;
    color: #fff;
    line-height: 24px;
    font-size: 14px;
    margin: 0 0 0 68px;
}

.contact .info-box .social-links {
    margin: 5px 0 0 68px;
    display: flex;
}

.contact .info-box .social-links a {
    font-size: 18px;
    display: inline-block;
    color: #fff;
    line-height: 1;
    margin-right: 12px;
    transition: 0.3s;
}

.contact .info-box .social-links a:hover {
    color: #18d26e;
}

.contact .php-email-form {
    padding: 30px;
    background: rgba(255, 255, 255, 0.08);
}

.contact .php-email-form .error-message {
    display: none;
    background: rgba(255, 255, 255, 0.08);
    background: #ed3c0d;
    text-align: left;
    padding: 15px;
    font-weight: 600;
}

.contact .php-email-form .error-message br + br {
    margin-top: 25px;
}

.contact .php-email-form .sent-message {
    display: none;
    background: rgba(255, 255, 255, 0.08);
    background: #18d26e;
    text-align: center;
    padding: 15px;
    font-weight: 600;
}

.contact .php-email-form .loading {
    /*display: none;*/
    background: rgba(255, 255, 255, 0.08);
    text-align: center;
    padding: 15px;
}

.contact .php-email-form .loading:before {
    content: "";
    display: inline-block;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    margin: 0 10px -6px 0;
    border: 3px solid #18d26e;
    border-top-color: #eee;
    -webkit-animation: animate-loading 1s linear infinite;
    animation: animate-loading 1s linear infinite;
}

.contact .php-email-form input, .contact .php-email-form textarea {
    border-radius: 0;
    box-shadow: none;
    font-size: 14px;
    background: rgba(255, 255, 255, 0.08);
    border: 0;
    transition: 0.3s;
    color: #fff;
}

.contact .php-email-form input:focus, .contact .php-email-form textarea:focus {
    background-color: rgba(255, 255, 255, 0.11);
}

.contact .php-email-form input::-webkit-input-placeholder, .contact .php-email-form textarea::-webkit-input-placeholder {
    color: rgba(255, 255, 255, 0.3);
    opacity: 1;
}

.contact .php-email-form input:-ms-input-placeholder, .contact .php-email-form textarea:-ms-input-placeholder {
    color: rgba(255, 255, 255, 0.3);
    opacity: 1;
}

.contact .php-email-form input::placeholder, .contact .php-email-form textarea::placeholder {
    color: rgba(255, 255, 255, 0.3);
    opacity: 1;
}

.contact .php-email-form input {
    padding: 10px 15px;
}

.contact .php-email-form textarea {
    padding: 12px 15px;
}



.contact .php-email-form button[type="submit"]:hover {
    background: #15bb62;
}

@-webkit-keyframes animate-loading {
    0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

@keyframes animate-loading {
    0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}
.form_home_component_label-color__-T-JL{
    color: black!important;
    font-weight: bold;
}
.form_social_component_label-color__1SqLZ{
    color: black!important;
    font-weight: bold;
}
.pagination {
    margin: 15px auto;
    display: flex;
    list-style: none;
    outline: none;
}

.pagination > .active > a {
    background-color: #47ccde;
    border-color: #47ccde;
    color: #fff;
}
.margin-auto{
    margin: auto;
}

.pagination > li > a {
    border: 1px solid #47ccde;
    padding: 5px 10px;
    outline: none;
    cursor: pointer;
}

.pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus {
    background-color: #47ccde;
    border-color: #47ccde;
    outline: none;
}

.pagination > li > a, .pagination > li > span {
    color: #47ccde
}

.pagination > li:first-child > a, .pagination > li:first-child > span, .pagination > li:last-child > a, .pagination > li:last-child > span {
    border-radius: unset
}
.form_about_component_label-color__2gX_q{
    color: black!important;
    font-weight: bold;
}
.form_fact_component_label-color__1cfSh{
    color: black!important;
    font-weight: bold;
}
.pagination {
    margin: 15px auto;
    display: flex;
    list-style: none;
    outline: none;
}

.pagination > .active > a {
    background-color: #47ccde;
    border-color: #47ccde;
    color: #fff;
}
.margin-auto{
    margin: auto;
}

.pagination > li > a {
    border: 1px solid #47ccde;
    padding: 5px 10px;
    outline: none;
    cursor: pointer;
}

.pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus {
    background-color: #47ccde;
    border-color: #47ccde;
    outline: none;
}

.pagination > li > a, .pagination > li > span {
    color: #47ccde
}

.pagination > li:first-child > a, .pagination > li:first-child > span, .pagination > li:last-child > a, .pagination > li:last-child > span {
    border-radius: unset
}
.label-color{
    color: black!important;
    font-weight: bold;
}
.pagination {
    margin: 15px auto;
    display: flex;
    list-style: none;
    outline: none;
}

.pagination > .active > a {
    background-color: #47ccde;
    border-color: #47ccde;
    color: #fff;
}
.margin-auto{
    margin: auto;
}

.pagination > li > a {
    border: 1px solid #47ccde;
    padding: 5px 10px;
    outline: none;
    cursor: pointer;
}

.pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus {
    background-color: #47ccde;
    border-color: #47ccde;
    outline: none;
}

.pagination > li > a, .pagination > li > span {
    color: #47ccde
}

.pagination > li:first-child > a, .pagination > li:first-child > span, .pagination > li:last-child > a, .pagination > li:last-child > span {
    border-radius: unset
}
.label-color{
    color: black!important;
    font-weight: bold;
}
.pagination {
    margin: 15px auto;
    display: flex;
    list-style: none;
    outline: none;
}

.pagination > .active > a {
    background-color: #47ccde;
    border-color: #47ccde;
    color: #fff;
}
.margin-auto{
    margin: auto;
}

.pagination > li > a {
    border: 1px solid #47ccde;
    padding: 5px 10px;
    outline: none;
    cursor: pointer;
}

.pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus {
    background-color: #47ccde;
    border-color: #47ccde;
    outline: none;
}

.pagination > li > a, .pagination > li > span {
    color: #47ccde
}

.pagination > li:first-child > a, .pagination > li:first-child > span, .pagination > li:last-child > a, .pagination > li:last-child > span {
    border-radius: unset
}
.form_contact_component_label-color__2mme5{
    color: black!important;
    font-weight: bold;
}
.label-color{
    color: black!important;
    font-weight: bold;
}
.pagination {
    margin: 15px auto;
    display: flex;
    list-style: none;
    outline: none;
}

.pagination > .active > a {
    background-color: #47ccde;
    border-color: #47ccde;
    color: #fff;
}
.margin-auto{
    margin: auto;
}

.pagination > li > a {
    border: 1px solid #47ccde;
    padding: 5px 10px;
    outline: none;
    cursor: pointer;
}

.pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus {
    background-color: #47ccde;
    border-color: #47ccde;
    outline: none;
}

.pagination > li > a, .pagination > li > span {
    color: #47ccde
}

.pagination > li:first-child > a, .pagination > li:first-child > span, .pagination > li:last-child > a, .pagination > li:last-child > span {
    border-radius: unset
}
.label-color{
    color: black!important;
    font-weight: bold;
}
.pagination {
    margin: 15px auto;
    display: flex;
    list-style: none;
    outline: none;
}

.pagination > .active > a {
    background-color: #47ccde;
    border-color: #47ccde;
    color: #fff;
}
.margin-auto{
    margin: auto;
}

.pagination > li > a {
    border: 1px solid #47ccde;
    padding: 5px 10px;
    outline: none;
    cursor: pointer;
}

.pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus {
    background-color: #47ccde;
    border-color: #47ccde;
    outline: none;
}

.pagination > li > a, .pagination > li > span {
    color: #47ccde
}

.pagination > li:first-child > a, .pagination > li:first-child > span, .pagination > li:last-child > a, .pagination > li:last-child > span {
    border-radius: unset
}
.form_summary_component_label-color__10RsW{
    color: black!important;
    font-weight: bold;
}
.label-color{
    color: black!important;
    font-weight: bold;
}
.pagination {
    margin: 15px auto;
    display: flex;
    list-style: none;
    outline: none;
}

.pagination > .active > a {
    background-color: #47ccde;
    border-color: #47ccde;
    color: #fff;
}
.margin-auto{
    margin: auto;
}

.pagination > li > a {
    border: 1px solid #47ccde;
    padding: 5px 10px;
    outline: none;
    cursor: pointer;
}

.pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus {
    background-color: #47ccde;
    border-color: #47ccde;
    outline: none;
}

.pagination > li > a, .pagination > li > span {
    color: #47ccde
}

.pagination > li:first-child > a, .pagination > li:first-child > span, .pagination > li:last-child > a, .pagination > li:last-child > span {
    border-radius: unset
}
.label-color{
    color: black!important;
    font-weight: bold;
}
.pagination {
    margin: 15px auto;
    display: flex;
    list-style: none;
    outline: none;
}

.pagination > .active > a {
    background-color: #47ccde;
    border-color: #47ccde;
    color: #fff;
}
.margin-auto{
    margin: auto;
}

.pagination > li > a {
    border: 1px solid #47ccde;
    padding: 5px 10px;
    outline: none;
    cursor: pointer;
}

.pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus {
    background-color: #47ccde;
    border-color: #47ccde;
    outline: none;
}

.pagination > li > a, .pagination > li > span {
    color: #47ccde
}

.pagination > li:first-child > a, .pagination > li:first-child > span, .pagination > li:last-child > a, .pagination > li:last-child > span {
    border-radius: unset
}
.label-color{
    color: black!important;
    font-weight: bold;
}
.pagination {
    margin: 15px auto;
    display: flex;
    list-style: none;
    outline: none;
}

.pagination > .active > a {
    background-color: #47ccde;
    border-color: #47ccde;
    color: #fff;
}
.margin-auto{
    margin: auto;
}

.pagination > li > a {
    border: 1px solid #47ccde;
    padding: 5px 10px;
    outline: none;
    cursor: pointer;
}

.pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus {
    background-color: #47ccde;
    border-color: #47ccde;
    outline: none;
}

.pagination > li > a, .pagination > li > span {
    color: #47ccde
}

.pagination > li:first-child > a, .pagination > li:first-child > span, .pagination > li:last-child > a, .pagination > li:last-child > span {
    border-radius: unset
}
.each-slide > div {
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: 100% 100%;

    width: 100%;
    height: 600px;
}

.each-slide span {
    padding: 20px;
    font-size: 20px;
    background: #efefef;
    text-align: center;
}
.label-color{
    color: black!important;
    font-weight: bold;
}


.img-mythumbnail {
    padding: .25rem;
    background-color: #fff;
    border: 1px solid #dee2e6;
    border-radius: .25rem;
    max-width: 100%;
}
.pagination {
    margin: 15px auto;
    display: flex;
    list-style: none;
    outline: none;
}

.pagination > .active > a {
    background-color: #47ccde;
    border-color: #47ccde;
    color: #fff;
}
.margin-auto{
    margin: auto;
}

.pagination > li > a {
    border: 1px solid #47ccde;
    padding: 5px 10px;
    outline: none;
    cursor: pointer;
}

.pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus {
    background-color: #47ccde;
    border-color: #47ccde;
    outline: none;
}

.pagination > li > a, .pagination > li > span {
    color: #47ccde
}

.pagination > li:first-child > a, .pagination > li:first-child > span, .pagination > li:last-child > a, .pagination > li:last-child > span {
    border-radius: unset
}
