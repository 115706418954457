/*--------------------------------------------------------------
# Services
--------------------------------------------------------------*/
.services .icon-box {
    width: 100%;
    text-align: center;
    background: rgba(204, 204, 204, 0.1);
    padding: 80px 20px;
    transition: all ease-in-out 0.3s;
}

.services .icon-box .icon {
    margin: 0 auto;
    width: 64px;
    height: 64px;
    background: #18d26e;
    border-radius: 5px;
    transition: all .3s ease-out 0s;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    transform-style: preserve-3d;
}

.services .icon-box .icon i {
    color: #fff;
    font-size: 28px;
}

.services .icon-box .icon::before {
    position: absolute;
    content: '';
    left: -8px;
    top: -8px;
    height: 100%;
    width: 100%;
    background: rgba(255, 255, 255, 0.15);
    border-radius: 5px;
    transition: all .3s ease-out 0s;
    transform: translateZ(-1px);
}

.services .icon-box h4 {
    font-weight: 700;
    margin-bottom: 15px;
    font-size: 24px;
}

.services .icon-box h4 a {
    color: #fff;
}

.services .icon-box p {
    line-height: 24px;
    font-size: 14px;
    margin-bottom: 0;
}

.services .icon-box:hover {
    background: #18d26e;
    border-color: #18d26e;
}

.services .icon-box:hover .icon {
    background: #fff;
}

.services .icon-box:hover .icon i {
    color: #18d26e;
}

.services .icon-box:hover .icon::before {
    background: #35e888;
}

.services .icon-box:hover h4 a, .services .icon-box:hover p {
    color: #fff;
}